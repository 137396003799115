import { Button, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { colors } from "../../Utils/temas";
import { requestProvision } from "./request";
import { ModalInsertEdit } from "./components/modals/insertEdit";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModalDelete } from "./components/modals/delete";

export const Provimento = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [loading, setLoading] = useState(false);

  const [provimentos, setProvimentos] = useState([
    { id: null, descricao: "", situacao: "" },
  ]);

  const [idProvision, setIdProvision] = useState(null);
  const [idDelete, setIdDelete] = useState(null);

  useEffect(() => {
    if (!open) {
      getProvisions(1, 10);
    }
  }, [open]);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      render: (e) => (e === "A" ? "Ativo" : "Inativo"),
    },

    {
      title: "Ações",
      render: (_, render) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(render.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(render.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getProvisions = async (page, pageSize) => {
    setLoading(true);
    const response = await requestProvision.getProvisions(page, pageSize);
    if (response.status === 200) {
      const { results } = response.data;
      setProvimentos(results);
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    setOpen(true);
    setIdProvision(id);
  };

  const handleDelete = (id) => {
    setOpenDelete(true);
    setIdDelete(id);
  };

  return (
    <div>
      <div style={{ padding: 10 }}>
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            color: "white",
            background: colors.secondary,
            fontWeight: "500",
          }}
          className="botao"
        >
          Inserir
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={provimentos}
        loading={loading}
        scroll={{ x: true }}
      />

      <ModalInsertEdit
        onCancel={() => {
          setOpen(false);
          setIdProvision(null);
        }}
        visible={open}
        id={idProvision}
      />
      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setIdProvision(null);
        }}
        visible={openDelete}
        onConfirm={() => {
          getProvisions(1, 10);
          setIdDelete(null);
        }}
        id={idDelete}
      />
    </div>
  );
};
