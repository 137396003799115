import { Button, Divider, Drawer, Form, Input } from "antd";
import React, { useEffect } from "react";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";
import { colors } from "../../../../../Utils/temas";
import { useForm } from "antd/lib/form/Form";

export const DrawerFilter = ({
  visible,
  onClose,
  onFinish,
  onClear,
  filtros,
}) => {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({ query: filtros.query });
  }, [filtros]);

  return (
    <Drawer title="Filtros" visible={visible} onClose={onClose}>
      <Form
        layout="vertical"
        form={form}
        onFinish={(value) => {
          onFinish(value);
          onClose();
        }}
      >
        <Form.Item label="Nome/ID:" style={{ fontWeight: "500" }} name="query">
          <Input />
        </Form.Item>

        <Divider />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            icon={<FilterOutlined />}
            htmlType="submit"
            style={{
              background: colors.secondary,
              color: "white",
              fontWeight: "500",
            }}
          >
            Filtrar
          </Button>
          <Button
            icon={<ClearOutlined />}
            onClick={() => {
              form.resetFields();
              onClose();
              onClear();
            }}
          >
            Limpar
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
