import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Table,
} from "antd";
import React from "react";
import { FilterOutlined, KeyOutlined, CloseOutlined } from "@ant-design/icons";
import { styles } from "../../Atualizacao-id/estilos";
import { colors } from "../../../../Utils/temas";

export const FiltroCartorios = ({
  onClick,
  onClose,
  visible,
  form,
  onFinish,
  limpar,
  loading,
  columns,
  cartorios,
  pagination,
  onCancel,
  onOk,
  footer,
  estadosDelete,
  openModalDelete,
  title,
}) => {
  return (
    <div>
      <div style={styles.containerLista}>
        <text style={styles.textCartorios}>Cartórios da atualização</text>

        <Button
          onClick={onClick}
          style={{ background: colors.secondary, color: "white" }}
          className="botao"
        >
          <FilterOutlined />
        </Button>
        <Drawer
          title="Filtro"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            preserve={true}
          >
            <Col>
              <Form.Item name="nome_cartorio" label="Nome Cartório:">
                <Input />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item name="status" label="Status:">
                <Select
                  options={[
                    { value: "C", label: "Criada" },
                    { value: "V", label: "Enviada" },
                    { value: "I", label: "Iniciada" },
                    { value: "O", label: "Concluida" },
                    { value: "E", label: "Erro" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Divider style={{ background: "#DCDCDC" }} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>

              <Button onClick={limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={cartorios}
        pagination={pagination}
        style={{ marginTop: 10 }}
        title={title}
        scroll={{ x: true }}
      />
      <Modal
        visible={openModalDelete}
        onCancel={onCancel}
        onOk={onOk}
        footer={footer}
      >
        {estadosDelete}
      </Modal>
    </div>
  );
};
