import { useEffect, useState, React } from "react";
import {
  Button,
  Drawer,
  Form,
  Space,
  Table,
  Col,
  Row,
  Input,
  Divider,
  Tag,
  Tooltip,
  Radio,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import ConfigPadraoRequest from "./ConfigPadraoRequest";
import CadastroConfigPadrao from "./CadastroConfigPadrao";
import { ModalDelete } from "./modais/modalDelete";
import { colors } from "../../Utils/temas";
const { Group } = Radio;

const ConfigPadrao = () => {
  const [configPadrao, setConfigPadrao] = useState([]);
  const [filtros, setFiltros] = useState([]);

  const [id, setId] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [idDelete, setIdDelete] = useState(null);

  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [form_pesquisa] = Form.useForm();

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await ConfigPadraoRequest.getListaConfigPadrao(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setConfigPadrao(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    setDrawerVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onInserirClick() {
    showModal();
  }

  async function onEditConfigPadrao(id) {
    showModal();
    setId(id);
  }

  function onDeletarConfigPadrao(id) {
    setOpenDelete(true);
    setIdDelete(id);
  }

  useEffect(() => {
    FetchRecords(1, 10, filtros);
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data Alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const date = new Date(value);
        const dateFormatted = date.toLocaleString().replace(",", "");
        return dateFormatted;
      },
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Tabela",
      dataIndex: "tabela",
    },
    {
      title: "Key Field",
      dataIndex: "key_field",
    },
    {
      title: "List Field",
      dataIndex: "list_field",
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (valor) => {
        const tagFormat = () => {
          if (valor) {
            return "Sim";
          }
          return "Não";
        };
        return <Tag color={valor ? "green" : "red"}>{tagFormat()}</Tag>;
      },
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditConfigPadrao(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarConfigPadrao(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tags = {
    descricao: "Descrição",
    tabela: "Tabela",
    em_teste: "Em teste",
  };

  const onRemoverFiltros = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagColors = () => {
            if (key === "descricao") {
              return "gold";
            } else if (key === "em_teste" && value) {
              return "green";
            } else if (key === "tabela") {
              return "blue";
            }
            return "red";
          };

          if (value === "") return undefined;

          return (
            <Tag
              closable
              onClose={() => onRemoverFiltros(key)}
              key={key}
              color={tagColors(key)}
            >
              {tags[key] +
                ": " +
                (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={400}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
            style={{ height: "100%" }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="descricao" label="Descrição">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="tabela" label="Tabela">
              <Input />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              style={{ fontWeight: "500" }}
            >
              <Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Group>
            </Form.Item>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "68%",
              }}
            >
              <Divider style={{ background: "#DCDCDC" }} />

              <Row>
                <Col span={10}>
                  <Button
                    htmlType="submit"
                    style={{
                      background: colors.secondary,
                      fontWeight: "500",
                      color: "white",
                    }}
                    className="botao"
                  >
                    <KeyOutlined />
                    Pesquisar
                  </Button>
                </Col>

                <Col span={5} push={7}>
                  <Button onClick={Limpar}>
                    <CloseOutlined />
                    Limpar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey={"id"}
        dataSource={configPadrao}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        title={getTags}
        scroll={{ x: true }}
      />

      <CadastroConfigPadrao
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        estadoPadrao={setConfigPadrao}
        id={id}
      />

      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setIdDelete(null);
        }}
        visible={openDelete}
        estadoConfig={setConfigPadrao}
        id={idDelete}
      />
    </div>
  );
};

export default ConfigPadrao;
