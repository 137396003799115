import { Button, Modal, Result } from "antd";
import React from "react";

export const ModalError = ({ visible, onCancel, title, subtitle }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} footer={false}>
      <Result
        status="500"
        title={title}
        subTitle={subtitle}
        extra={
          <Button type="primary" onClick={onCancel}>
            Voltar
          </Button>
        }
      />
    </Modal>
  );
};
