import axios from "axios";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${process.env.REACT_APP_Z_API}` },
    params: params,
  };
};

const getInstances = (page, params) => {
  const encodedParams = Object.keys(params).reduce((acc, key) => {
    acc[key] = encodeURIComponent(params[key]);
    return acc;
  }, {});
  const config = getConfig(encodedParams);

  const response = axios.get(
    `https://api.z-api.io/instances/?page=${page}&pageSize=15`,
    config
  );
  return response;
};

const postInstance = (data) => {
  const config = getConfig();
  const response = axios.post(
    "https://api.z-api.io/instances/integrator/on-demand",
    data,
    config
  );
  return response;
};

const signInstance = (id, token) => {
  const config = getConfig();
  const response = axios.post(
    `https://api.z-api.io/instances/${id}/token/${token}/integrator/on-demand/subscription`,
    {},
    config
  );
  return response;
};

const cancelInstance = (id, token) => {
  const config = getConfig();
  const response = axios.post(
    `https://api.z-api.io/instances/${id}/token/${token}/integrator/on-demand/cancel`,
    {},
    config
  );
  return response;
};

export const requestsZAPI = {
  getInstances,
  postInstance,
  signInstance,
  cancelInstance,
};
