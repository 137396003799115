/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Collapse, Skeleton, Space, Table, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { colors } from "../../Utils/temas";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { ModalAdd } from "./modal";
import { newConfigRequest } from "./request";
import { ModalDelete } from "./modalDelete";
import { DrawerEdit } from "./drawerFilter";
import { sistemaRequest } from "../../Utils/sistemaRequest";

export const NewConfig = () => {
  const [config, setConfig] = useState([{ secao: "" }]);
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);
  const [configs, setConfigs] = useState([
    {
      marcar_excluir: false,
      marcar_atualizar: false,
      tipo_valor: "",
      config_padrao_descricao: "",
      nome: "",
      valor: "",
    },
  ]);
  const [configsGrupo, setConfigsGrupo] = useState([
    {
      id: 0,
      descricao: "",
      sistema_id: 0,
      sistema_descricao: "",
      em_teste: false,
      usuario_id: null,
      data_alteracao: "",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [idConfig, setIdConfig] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);

  const [filtros, setFiltros] = useState({
    secao: "",
    config_grupo_id: null,
    config_grupo__sistema_id: null,
  });

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    if (filtros.config_grupo__sistema_id) {
      getConfigGrupos({ sistema_id: filtros.config_grupo__sistema_id });
    }
  }, [filtros.config_grupo__sistema_id, sistemas]);

  useEffect(() => {
    if (filtros.config_grupo_id) {
      getConfigBySystem({
        config_grupo__sistema_id: filtros.config_grupo__sistema_id,
        config_grupo_id: filtros.config_grupo_id,
      });
    }
  }, [filtros.config_grupo__sistema_id, filtros.config_grupo_id]);

  useEffect(() => {
    if (filtros.secao) {
      getListaConfig(1, 10, filtros);
    }
  }, [filtros]);

  const getSistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        const notSystems = ["ADMINISTRATIVO"];

        const sistemasFiltrados = results.filter(
          (item) => !notSystems.includes(item.nome_sistema)
        );
        setSistemas(sistemasFiltrados);
        setFiltros((prevState) => ({
          ...prevState,
          config_grupo__sistema_id: sistemasFiltrados[0].id,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { TabPane } = Tabs;

  const { Panel } = Collapse;

  const getConfigBySystem = async (params) => {
    setLoadingGrid(true);
    try {
      const response = await newConfigRequest.newGetListaConfig(params);
      if (response.status === 200 || response.status === 201) {
        setConfig(response.data);
        setLoadingGrid(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const arraySecao = config.map((itemSecao) => itemSecao.secao);

  const arraySecaoUnico = [...new Set(arraySecao)];

  const handleOpenEdit = (id) => {
    setOpen(true);
    setIdConfig(id);
  };

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setIdDelete(id);
  };

  const columns = [
    { title: "Nome", dataIndex: "nome" },
    { title: "Descrição", dataIndex: "descricao" },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    {
      title: "Ações",
      render: (_, values) => (
        <Space size="small">
          <EditOutlined onClick={() => handleOpenEdit(values.id)} />
          <DeleteOutlined onClick={() => handleOpenDelete(values.id)} />
        </Space>
      ),
    },
  ];

  const getListaConfig = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await newConfigRequest.getListaConfig(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setConfigs(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getConfigGrupos = async (params) => {
    try {
      const response = await newConfigRequest.getListaConfigGrupo(params);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        setConfigsGrupo(data);
        setFiltros((prevState) => ({
          ...prevState,
          config_grupo_id: data[0].id,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filtrar = (values) => {
    setOpenDrawer(false);

    setFiltros((prevState) => ({
      ...prevState,
      nome: values.nome,
      em_teste: values.em_teste,
    }));
  };

  const limpar = () => {
    setOpenDrawer(false);

    setFiltros((prevState) => ({
      ...prevState,
      nome: undefined,
      em_teste: null,
    }));
  };

  const tags = {
    nome: "Nome",
    em_teste: "Em teste",
  };

  const onRemoverFiltros = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const colorsTag = () => {
            if (key === "em_teste" && value) return "green";
            else if (key === "em_teste" && !value) return "red";
            return "magenta";
          };

          if (key !== "em_teste" && key !== "nome") {
            return null;
          }
          if (key === "em_teste" && value === null) {
            return null;
          }

          return (
            <Tag
              closable
              onClose={() => onRemoverFiltros(key)}
              key={key}
              color={colorsTag(key)}
            >
              {tags[key] +
                ": " +
                (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
            </Tag>
          );
        })}
    </>
  );

  return (
    <>
      <Tabs
        type="card"
        size="large"
        onChange={(e) =>
          setFiltros((prevState) => ({
            ...prevState,
            config_grupo__sistema_id: e,
          }))
        }
      >
        {sistemas.map((item) => (
          <TabPane key={item.id} tab={item.nome_sistema}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                height: "100vh",
              }}
            >
              <div
                style={{
                  width: "20%",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                {configsGrupo.map((item) => (
                  <div
                    style={{
                      border: "1px solid",
                      padding: 4,
                      borderRadius: 10,
                      marginBottom: 10,
                      cursor: "pointer",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: "100%",
                      boxShadow:
                        filtros.config_grupo_id === item.id
                          ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                          : null,
                      borderColor:
                        filtros.config_grupo_id === item.id
                          ? colors.secondary
                          : null,
                      background:
                        filtros.config_grupo_id === item.id
                          ? colors.secondary
                          : null,
                      color:
                        filtros.config_grupo_id === item.id ? "white" : "black",
                    }}
                    onClick={() =>
                      setFiltros((prevState) => ({
                        ...prevState,
                        config_grupo_id: item.id,
                      }))
                    }
                  >
                    <text
                      style={{
                        fontWeight: "500",
                        fontSize: 18,
                      }}
                    >
                      {item.descricao}
                    </text>
                  </div>
                ))}
              </div>

              <div style={{ width: "76%" }}>
                {loadingGrid ? (
                  <div style={{ width: "100%" }}>
                    <Skeleton style={{ width: "100%" }} />
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        style={{
                          background: colors.secondary,
                          color: "white",
                          fontWeight: "500",
                        }}
                        className="botao"
                        onClick={() => {
                          setOpen(true);
                          setIdConfig(null);
                        }}
                      >
                        <PlusOutlined
                          color="white"
                          style={{ color: "white" }}
                        />
                        Inserir
                      </Button>

                      <Button
                        icon={<FilterOutlined />}
                        className="botao"
                        style={{
                          background: colors.secondary,
                          color: "white",
                          fontWeight: "500",
                          marginLeft: 10,
                        }}
                        onClick={() => setOpenDrawer(true)}
                      >
                        Filtros
                      </Button>

                      <div style={{ marginLeft: 10 }}>{getTags()}</div>
                    </div>
                    <div style={{ height: "96%", overflow: "auto" }}>
                      <Collapse
                        accordion={true}
                        onChange={(e) =>
                          setFiltros((prevState) => ({
                            ...prevState,
                            secao: e,
                          }))
                        }
                      >
                        {arraySecaoUnico.sort().map((item) => (
                          <Panel header={item} key={item}>
                            <Table
                              columns={columns}
                              dataSource={configs}
                              loading={loading}
                              pagination={{
                                total: totalRecords,
                                onChange: (page, pageSize) =>
                                  getListaConfig(page, pageSize, {
                                    secao: filtros.secao,
                                    config_grupo_id: filtros.config_grupo_id,
                                    config_grupo__sistema_id:
                                      filtros.config_grupo__sistema_id,
                                  }),
                              }}
                              scroll={{ x: true }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </>
                )}
              </div>
            </div>
          </TabPane>
        ))}
      </Tabs>

      <ModalAdd
        visible={open}
        onCancel={() => {
          setOpen(false);
          setIdConfig(null);
        }}
        secao={filtros.secao}
        idGrupo={filtros.config_grupo_id}
        atualizarLista={(item) => {
          if (!arraySecaoUnico.includes(item.secao)) {
            getConfigBySystem({
              config_grupo__sistema_id: filtros.config_grupo__sistema_id,
              config_grupo_id: filtros.config_grupo_id,
            });
          } else {
            setConfigs((prevState) => [...prevState, item]);
          }
        }}
        editarConfig={setConfigs}
        id={idConfig}
      />

      <ModalDelete
        visible={openDelete}
        onCancel={() => setOpenDelete(false)}
        id={idDelete}
        estadoConfig={() => {
          getListaConfig(1, 10, {
            secao: filtros.secao,
            config_grupo_id: filtros.config_grupo_id,
            config_grupo__sistema_id: filtros.config_grupo__sistema_id,
          });
        }}
      />

      <DrawerEdit
        visible={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setIdConfig(null);
        }}
        onFinish={(e) => filtrar(e)}
        limpar={limpar}
      />
    </>
  );
};
