import { Button, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { colors } from "../../Utils/temas";
import { sistemaRequest } from "../../Utils/sistemaRequest";
import { requestAlerts } from "./request";
import { ModalInserir } from "./components/modal/inserirEditar";
import { ModalDelete } from "./components/modal/delete";
import { DrawerFilter } from "./components/drawerFilter";
import { ufs } from "../../Constante/UFS";

export const Alerta = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [alertas, setAlertas] = useState([]);
  const [sistemas, setSistemas] = useState([]);

  const [idMsg, setIdMsg] = useState(null);

  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    listAlerts(1, 10, filtros);
  }, [filtros]);

  const listAlerts = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await requestAlerts.getAlerts(page, pageSize, params);
      if (response.status === 200) {
        const { results } = response.data;
        setAlertas(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleEdit = (value) => {
    setOpen(true);
    setIdMsg(value);
  };

  const handleDelete = (value) => {
    setOpenDelete(true);
    setIdMsg(value);
  };

  const dataFormatada = (data) => {
    const date = new Date(data);
    const dateFormatted = date.toLocaleString().replace(",", "");
    return dateFormatted;
  };

  const columns = [
    {
      title: "Mensagem",
      dataIndex: "mensagem",
    },
    {
      title: "Dt. Alerta",
      dataIndex: "data_alerta",
      render: (value) => <text>{dataFormatada(value)}</text>,
    },
    { title: "Sistema", dataIndex: "sistema_descricao" },
    {
      title: "UF",
      dataIndex: "uf",
    },
    {
      title: "Ações",
      render: (_, render) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(render.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(render.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const options = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });

  const getSistemas = async () => {
    const response = await sistemaRequest.getSistemas();
    if (response.status === 200) {
      const { results } = response.data;
      setSistemas(results);
    }
  };

  const optionsSistemas =
    sistemas &&
    sistemas.map((item) => ({ label: item.nome_sistema, value: item.id }));

  return (
    <div>
      <div
        style={{
          padding: 10,
          justifyContent: "space-between",
          width: "100%",
          display: "flex",
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            color: "white",
            background: colors.secondary,
            fontWeight: "500",
          }}
          className="botao"
        >
          Inserir
        </Button>

        <Button
          icon={<FilterOutlined />}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          onClick={() => setOpenDrawer(true)}
        >
          Filtros
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={alertas}
        loading={loading}
        scroll={{ x: true }}
      />

      <ModalInserir
        onCancel={() => {
          setOpen(false);
          setIdMsg(null);
        }}
        visible={open}
        onConfirm={() => listAlerts(1, 10, filtros)}
        id={idMsg}
        options={options}
        optionsSistemas={optionsSistemas}
      />

      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setIdMsg(null);
        }}
        visible={openDelete}
        id={idMsg}
        onConfirm={() => listAlerts(1, 10, filtros)}
      />

      <DrawerFilter
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}
        options={options}
        optionsSistema={optionsSistemas}
        onFinish={(value) => {
          setOpenDrawer(false);
          setFiltros({ ...value, sistema: value.sistema_id });
        }}
        limpar={() => {
          setOpenDrawer(false);
          setFiltros({});
        }}
      />
    </div>
  );
};
