/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Input, Select, DatePicker, Form, Switch, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import { Radio } from "antd";
import { ufs } from "../../Constante/UFS";
import CartorioRequest from "./CartorioRequest";
import SelEmolumentoPeriodo from "../../Selecao/EmolumentoPeriodo/Sel_EmolumentoPeriodo";
import moment from "moment";
import { useUserContext } from "../../contexts/usersContext";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

const { useForm } = Form;

function CadastroCartorio({ visible, onCancel, estadoCartorio, id }) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalEmolumentoPeriodoVisible, setIsModalEmolumentoPeriodoVisible] =
    useState(false);
  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [provimento, setProvimento] = useState([
    { id: null, descricao: "", situacao: "" },
  ]);

  const [form] = useForm();

  const { currentUser } = useUserContext();

  useEffect(() => {
    if (id) {
      getCartorioId();
    }
  }, [id]);

  useEffect(() => {
    if (visible) {
      getProvimento74();
    }
  }, [visible]);

  const options = ufs.map((uf) => {
    return {
      label: uf.nome,
      value: uf.sigla,
    };
  });

  async function CadastrarCartorio(cartorio) {
    try {
      const response = await CartorioRequest.CadastrarCartorio(cartorio, id);

      if (response.status === 201) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        estadoCartorio((prevCartorio) => [response.data, ...prevCartorio]);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoCartorio((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarCartorio(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  function onSelecionarEmolumentoPeriodo(valor) {
    setIsModalEmolumentoPeriodoVisible(false);
    form.setFieldsValue({
      emolumento_periodo_id: valor.id,
      emolumento_periodo_descricao: valor.descricao,
    });
  }

  const getCartorioId = async () => {
    setLoading(true);
    try {
      const response = await CartorioRequest.getCartorio(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        form.setFieldsValue({
          nome_cartorio: data.nome_cartorio,
          uf: data.uf,
          qtde_licenca: data.qtde_licenca,
          situacao: data.situacao,
          usa_os: data.usa_os,
          usa_pix: data.usa_pix,
          bloqueio: data.bloqueio,
          usa_api: data.usa_api,
          usa_whatszap: data.usa_whatszap,
          emolumento_periodo_descricao: data.emolumento_periodo_descricao,
          emolumento_periodo: data.emolumento_periodo,
          dia_vencimento: data.dia_vencimento,
          data_vencimento: data.data_vencimento
            ? moment(data.data_vencimento)
            : undefined,
          data_liberacao: data.data_liberacao
            ? moment(data.data_liberacao)
            : undefined,
          data_implantacao: data.data_implantacao
            ? moment(data.data_implantacao)
            : undefined,
          data_bloqueio: data.data_bloqueio
            ? moment(data.data_bloqueio)
            : undefined,
          data_atualizacao: data.data_atualizacao
            ? moment(data.data_atualizacao)
            : undefined,
          atualizar_emolumento: data.atualizar_emolumento,
          cpfcnpj: data.cpfcnpj,
          cidade: data.cidade,
          usa_cad_ato: data.usa_cad_ato,
          em_aberto: data.em_aberto,
          chave: data.chave,
          atualizar_com_erro: data.atualizar_com_erro,
          bloquear_atualizacao: data.bloquear_atualizacao,
          usa_bi: data.usa_bi,
          usa_workflow: data.usa_workflow,
          usa_gsti: data.usa_gsti,
          cns: data.cns,
          situacao_serventia: data.situacao_serventia,
          versao_banco: data.versao_banco,
          provimento74_id: data.provimento74_id,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getProvimento74 = async () => {
    const response = await CartorioRequest.getProvimento74();
    if (response.status === 200) {
      const { results } = response.data;
      setProvimento(results);
    }
  };

  const optionsProvimento = provimento.map((item) => ({
    value: item.id,
    label: item.descricao,
  }));

  return (
    <>
      <Modal
        width={780}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okButtonProps={{
          disabled:
            !currentUser.is_superuser &&
            !currentUser.is_manager &&
            !currentUser.is_financial,
        }}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            preserve={false}
            disabled={
              !currentUser.is_superuser &&
              !currentUser.is_manager &&
              !currentUser.is_financial
            }
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Form.Item name="id" style={{ display: "none" }} />
              <Form.Item
                name="nome_cartorio"
                label="Nome cartório:"
                rules={[
                  {
                    required: true,
                    message: "Informe o nome do cartorio",
                  },
                ]}
                style={{ marginBottom: 10, width: "77.6%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="cpfcnpj"
                label="CPF/CNPJ:"
                style={{ marginBottom: 10, width: "18%" }}
              >
                <Input maxLength={14} />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Form.Item
                name="cidade"
                label="Cidade:"
                rules={[
                  {
                    required: true,
                    message: "Informe a cidade",
                  },
                ]}
                style={{ marginBottom: 10, width: "77.6%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="uf"
                label="UF:"
                rules={[
                  {
                    required: true,
                    message: "Informe a UF",
                  },
                ]}
                style={{
                  marginBottom: 10,
                  width: "18%",
                }}
              >
                <Select name="uf" options={options} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="qtde_licenca"
                label="Qtd licença:"
                rules={[
                  {
                    required: true,
                    message: "Informe a quantidade de licença",
                  },
                ]}
                style={{ marginBottom: 10, width: 171 }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="data_implantacao"
                label="Data implantação:"
                style={{ marginBottom: 10, width: 171 }}
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item name="cns" label="CNS:" style={{ width: 171 }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="versao_banco"
                label="Versão DB:"
                style={{ width: 171 }}
              >
                <Select
                  options={[
                    { value: "FB25", label: "Firebird 2.5" },
                    { value: "FB30", label: "Firebird 3.0" },
                    { value: "FB50", label: "Firebird 5.0" },
                  ]}
                />
              </Form.Item>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Form.Item
                  name="situacao"
                  label="Situação:"
                  rules={[
                    {
                      required: true,
                      message: "Informe a situação",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={"A"}>Ativo</Radio>
                    <Radio value={"I"}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="situacao_serventia"
                  label="Situação Serventia:"
                >
                  <Radio.Group>
                    <Radio value={"P"}>Provido</Radio>
                    <Radio value={"V"}>Vago</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="bloqueio" label="Bloqueio">
                  <Radio.Group>
                    <Radio value={true}>Ativo</Radio>
                    <Radio value={false}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="provimento74_id"
                label="Provimento74"
                style={{ width: 132 }}
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigatório.",
                  },
                ]}
              >
                <Select options={optionsProvimento} />
              </Form.Item>
              <Form.Item
                name="usa_bi"
                label="Usa BI"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="usa_workflow"
                label="Usa Workflow"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="usa_os"
                label="Usa OS"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="usa_pix"
                label="Usa PIX"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="usa_api"
                label="Usa api"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="usa_whatszap"
                label="Usa WhatsZap"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="atualizar_emolumento"
                label="Atualizar Emol.:"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="usa_cad_ato"
                label="Usa Cadastro Ato:"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="em_aberto"
                label="Em Aberto:"
                valuePropName="checked"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                valuePropName="checked"
                label="Atualizar com erro:"
                name="atualizar_com_erro"
                style={{ width: 132 }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              {currentUser.is_superuser || currentUser.is_manager ? (
                <Form.Item
                  name="bloquear_atualizacao"
                  label="Bloquear atualização:"
                  valuePropName="checked"
                  style={{ width: 132 }}
                >
                  <Switch checkedChildren={true} unCheckedChildren={false} />
                </Form.Item>
              ) : null}
            </div>
          </Form>
        )}
        <SelEmolumentoPeriodo
          onSelecionado={(valor) => {
            onSelecionarEmolumentoPeriodo(valor);
          }}
          visible={isModalEmolumentoPeriodoVisible}
          onCancel={() => setIsModalEmolumentoPeriodoVisible(false)}
          optionsUf={options}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroCartorio;
