import { Modal } from "antd";
import React from "react";

export const ModalViewInstance = ({ visible, onCancel, onOk }) => (
  <Modal visible={visible} onCancel={onCancel} onOk={onOk}>
    <text style={{ fontSize: 16, fontWeight: "500" }}>
      Deseja visualizar esta instância?
    </text>
  </Modal>
);
