import { Button, Divider, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { requestsZAPI } from "../../../request";
import { useForm } from "antd/lib/form/Form";
import { ModalError } from "../error";

export const ModalCreateInstance = ({ visible, onCancel, onConfirm }) => {
  const [form] = useForm();

  const [openError, setOpenError] = useState(false);

  const createInstance = async (data) => {
    try {
      const response = await requestsZAPI.postInstance(data);
      if (response.status === 200) {
        const { data } = response;
        onCancel();
        form.resetFields();
        onConfirm({ id: data.id });
      }
    } catch (error) {
      onCancel();
      setOpenError(true);
      form.resetFields();
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        footer={null}
      >
        <text style={{ fontSize: 20, fontWeight: "700" }}>
          Deseja criar uma instância?
        </text>

        <div style={{ marginTop: 8 }}>
          <Form
            layout="vertical"
            form={form}
            onFinish={(value) => createInstance(value)}
          >
            <Form.Item
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
              label="Nome:"
              style={{ fontWeight: "500" }}
              name="name"
            >
              <Input placeholder="Digite o nome" />
            </Form.Item>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                  style={{ fontWeight: "500" }}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ fontWeight: "500" }}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <ModalError onCancel={() => setOpenError(false)} visible={openError} />
    </>
  );
};
