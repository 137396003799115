import { Modal } from "antd";
import React from "react";
import { requestProvision } from "../../../request";

export const ModalDelete = ({ visible, onCancel, id, onConfirm }) => {
  const deleteProvision = async () => {
    const response = await requestProvision.deleteProvision(id);
    if (response.status == 204) {
      onCancel();
      onConfirm();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={deleteProvision}
      title="Deletar"
    >
      <text style={{ fontWeight: "500", fontSize: 16 }}>
        Deseja realmente excluir este Provimento?
      </text>
    </Modal>
  );
};
