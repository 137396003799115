import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getProvisions = (page, pageSize) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}provimento74/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const getProvisionsId = (id) => {
  const url = `${urlBase}provimento74/${id}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const postProvision = (data, id) => {
  const url = id ? `${urlBase}provimento74/${id}` : `${urlBase}provimento74/`;
  const config = getConfig();
  const response = id
    ? axios.patch(url, data, config)
    : axios.post(url, data, config);
  return response;
};

const deleteProvision = (id) => {
  const url = `${urlBase}provimento74/${id}`;
  const config = getConfig();
  const response = axios.delete(url, config);
  return response;
};

export const requestProvision = {
  getProvisions,
  getProvisionsId,
  postProvision,
  deleteProvision,
};
