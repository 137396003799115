import { Button, Modal, Result } from "antd";
import React from "react";

export const ModalError = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="warning"
      title="Desculpe, ocorreu um erro ao criar uma instância."
      extra={
        <Button type="primary" key="console" onClick={onCancel}>
          Voltar
        </Button>
      }
    />
  </Modal>
);

export const ModalErrorSign = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="warning"
      title="Desculpe, ocorreu um erro ao assinar esta instância."
      extra={
        <Button type="primary" key="console" onClick={onCancel}>
          Voltar
        </Button>
      }
    />
  </Modal>
);

export const ModalErrorCancel = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="warning"
      title="Desculpe, ocorreu um erro ao cancelar esta instância."
      extra={
        <Button type="primary" key="console" onClick={onCancel}>
          Voltar
        </Button>
      }
    />
  </Modal>
);
