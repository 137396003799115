import { Button, Modal } from "antd";
import React, { useState } from "react";
import { requestsZAPI } from "../../../request";
import { ModalErrorCancel } from "../error";
import { colors } from "../../../../../Utils/temas";

export const ModalCancelInstance = ({
  onCancel,
  token,
  id,
  visible,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);

  const cancelInstance = async () => {
    setLoading(true);
    try {
      const response = await requestsZAPI.cancelInstance(id, token);
      if (response.status === 200) {
        onCancel();
        onConfirm();
        setLoading(false);
      }
    } catch (error) {
      setOpenError(true);
      onCancel();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Cancelar</Button>,
          <Button
            loading={loading}
            onClick={cancelInstance}
            style={{
              background: colors.secondary,
              color: "white",
              fontWeight: "500",
            }}
          >
            Confirmar
          </Button>,
        ]}
      >
        <text style={{ fontSize: 20, fontWeight: "700" }}>
          Gostaria de cancelar esta instância?
        </text>
      </Modal>

      <ModalErrorCancel
        onCancel={() => setOpenError(false)}
        visible={openError}
      />
    </>
  );
};
