/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import foreignRequest from "./foreignRequest";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../../Utils/temas";
import { ModalInsert } from "./components/modalInsert";
const { Group } = Radio;

export const ForeignKey = ({ id, nomeTabela }) => {
  const [open, setOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [foreign, setForeign] = useState([]);
  const totalFk = foreign.length;

  const [values, setValues] = useState({ id: null });
  const [filtros, setFiltros] = useState({});

  const [idDelete, setIdDelete] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [formFiltro] = Form.useForm();

  useEffect(() => {
    getForeign(1, 10, filtros);
  }, [filtros]);

  const addFkTitle = () => `${nomeTabela}_FK_${totalFk + 1}`;

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Nome", dataIndex: "nome" },
    {
      title: "Delete Cascade",
      dataIndex: "delete_cascade",
      render: (record) => (
        <Tag color={record ? "green" : "red"}>{record ? "Sim" : "Não"}</Tag>
      ),
    },
    { title: "Nome campo", dataIndex: "campo__nome" },
    { title: "Tabela Referência", dataIndex: "campo_referencia__tabela__nome" },
    {
      title: "Nome Campo Referência",
      dataIndex: "campo_referencia__nome",
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setOpen(true);
              handleEdit(record);
            }}
          />
          <DeleteOutlined onClick={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const getForeign = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await foreignRequest.getForeign(
        id,
        page,
        pageSize,
        params
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setForeign(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (valor) => {
    setValues({ id: valor.id });
  };

  const handleDelete = (valor) => {
    setIdDelete(valor);
    setOpenModalDelete(true);
  };

  const onPesquisarClick = (value) => {
    setFiltros(value);
    setOpenFilter(false);
  };

  const onLimpar = () => {
    formFiltro.resetFields();
    setFiltros({});
    setOpenFilter(false);
  };

  const onRemoverFiltro = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const tags = {
    nome: "Nome",
    campo_referencia_nome: "Campo referência",
    campo_nome: "Campo",
    em_teste: "Em teste",
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const getColors = () => {
            if (key === "nome") {
              return "geekblue";
            } else if (key === "campo_referencia_nome") {
              return "purple";
            } else if (key === "campo_nome") {
              return "magenta";
            }
          };

          const tagValores = {
            true: "Sim",
            false: "Não",
          };

          return (
            <>
              {value || value === false ? (
                <Tag
                  closable
                  onClose={() => onRemoverFiltro(key)}
                  color={
                    key === "em_teste"
                      ? value
                        ? "green"
                        : "red"
                      : getColors(key)
                  }
                  key={key}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? tagValores[value] : value)}
                  </text>
                </Tag>
              ) : null}
            </>
          );
        })}
    </>
  );

  return (
    <div>
      <Table
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => setOpen(true)}
              className="botao"
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
            >
              <PlusOutlined /> Incluir
            </Button>
            <div style={{ marginLeft: 10 }}>
              <Button
                onClick={() => setOpenFilter(true)}
                className="botao"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
                icon={<SearchOutlined />}
              >
                Filtros
              </Button>
            </div>
            <div style={{ marginLeft: 10 }}>{getTags()}</div>
          </div>
        )}
        columns={columns}
        dataSource={foreign}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => getForeign(page, pageSize),
        }}
        loading={loading}
        scroll={{ x: true }}
      />

      <ModalDelete
        onCancel={() => setOpenModalDelete()}
        visible={openModalDelete}
        id={idDelete}
        onDelete={setForeign}
      />

      <Drawer visible={openFilter} onClose={() => setOpenFilter(false)}>
        <Form
          layout="vertical"
          form={formFiltro}
          onFinish={onPesquisarClick}
          style={{ height: "70%" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              label="Nome:"
              style={{ fontWeight: "500", width: "48%" }}
              name="nome"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Campo referência:"
              style={{ fontWeight: "500", width: "48%" }}
              name="campo_referencia_nome"
            >
              <Input />
            </Form.Item>
          </div>

          <Form.Item
            label="Campo:"
            style={{ fontWeight: "500" }}
            name="campo_nome"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="em_teste"
            label="Em teste:"
            style={{ margin: 0, fontWeight: "500" }}
          >
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Form.Item>

          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
              >
                <KeyOutlined /> Pesquisar
              </Button>
              <Button onClick={onLimpar} icon={<ClearOutlined />}>
                Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <ModalInsert
        open={open}
        onCancel={() => {
          setOpen(false);
          setValues({ id: null });
        }}
        nomeTabela={nomeTabela}
        id={id}
        estadoFk={setForeign}
        idEdit={values.id}
        initialValue={addFkTitle()}
      />
    </div>
  );
};
