import { Button, Divider, Form, Modal, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { requestAlerts } from "../../../request";
import TextArea from "antd/lib/input/TextArea";
import { useForm } from "antd/lib/form/Form";

export const ModalInserir = ({
  visible,
  onCancel,
  onConfirm,
  id,
  options,
  optionsSistemas,
}) => {
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    if (id && visible) {
      getAlert();
    }
  }, [id]);

  const postAlert = async (data) => {
    const response = await requestAlerts.postAlerts(data, id);
    if (response.status === 201) {
      onCancel();
      onConfirm();
      form.resetFields();
    }
    if (response.status === 200) {
      onCancel();
      onConfirm();
      form.resetFields();
    }
  };

  const getAlert = async () => {
    setLoading(true);
    try {
      const response = await requestAlerts.getAlertId(id);
      if (response.status === 200) {
        const { mensagem, uf, sistema_id } = response.data;
        setLoading(false);
        form.setFieldsValue({
          mensagem: mensagem,
          uf: uf,
          sistema_id: sistema_id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title={id ? "Editar" : "Inserir"}
      visible={visible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      footer={null}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          layout="vertical"
          onFinish={(value) =>
            postAlert({
              mensagem: value.mensagem,
              uf: value.uf,
              sistema_id: value.sistema_id,
            })
          }
          form={form}
        >
          <div>
            <Form.Item
              name="mensagem"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
              label="Mensagem:"
            >
              <TextArea placeholder="Digite seu alerta" rows={5} />
            </Form.Item>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "20%" }}>
              <Form.Item name="uf" label="UF:">
                <Select options={options} />
              </Form.Item>
            </div>

            <div style={{ width: "78%" }}>
              <Form.Item name="sistema_id" label="Sistema:">
                <Select options={optionsSistemas} />
              </Form.Item>
            </div>
          </div>

          <Divider />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                justifyContent: "space-between",
                width: "36%",
                display: "flex",
              }}
            >
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                Cancelar
              </Button>
              <Button
                style={{ background: "#F89116", color: "white" }}
                htmlType="submit"
              >
                Inserir
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};
