import { Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { requestProvision } from "../../../request";
import { useForm } from "antd/lib/form/Form";

export const ModalInsertEdit = ({ visible, onCancel, id }) => {
  const [form] = useForm();

  useEffect(() => {
    if (id !== null) {
      getProvisionId();
    }
  }, [id]);

  const options = [
    { value: "A", label: "Ativo" },
    { value: "I", label: "Inativo" },
  ];

  const postProvision = async (data) => {
    const response = await requestProvision.postProvision(data, id);
    if (response.status === 201) {
      onCancel();
      form.resetFields();
    }
    if (response.status === 200) {
      onCancel();
      form.resetFields();
    }
  };

  const handleOk = () =>
    form
      .validateFields()
      .then((values) => postProvision(values))
      .catch((info) => {
        console.error("erro:", info);
      });

  const getProvisionId = async () => {
    const response = await requestProvision.getProvisionsId(id);
    if (response.status === 200) {
      const { data } = response;

      form.setFieldsValue({
        id: data.id,
        descricao: data.descricao,
        situacao: data.situacao,
      });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={handleOk}
      destroyOnClose
    >
      <Form layout="vertical" form={form} preserve={false}>
        <Form.Item
          name="descricao"
          label="Descrição:"
          rules={[
            { required: true, message: "Este campo não pode ser em branco." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="situacao"
          label="Situação:"
          style={{ width: 240 }}
          rules={[
            { required: true, message: "Este campo não pode ser em branco." },
          ]}
        >
          <Select options={options} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
