import { Button, Space, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { sequencesRequest } from "./sequencesRequest";
import { ModalInserir } from "./componentes/modais/modalInserir";
import { DrawerFilter } from "./componentes/drawer";
import { ModalDelete } from "./componentes/modais/modalDelete";
import { colors } from "../../Utils/temas";

export const Sequences = () => {
  const [sequences, setSequences] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filter, setFilter] = useState({});

  useEffect(() => {
    fetchRequest(1, 10, filter);
  }, [filter]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Nome", dataIndex: "nome" },
    { title: "Valor padrão", dataIndex: "valor_padrao" },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (_, record) => (
        <Space size="large">
          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleEdit = (value) => {
    setId(value);
    setOpen(true);
  };

  const handleDelete = (value) => {
    setIdDelete(value);
    setOpenDelete(true);
  };

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await sequencesRequest.getSequences(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setSequences(results);
        setLoading(false);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onPesquisarClick = (e) => {
    setFilter(e);
    setOpenFilter(false);
  };

  const tag = {
    nome: "Nome",
    em_teste: "Em teste",
  };

  const onRemoveTags = (key) => {
    const novosFiltros = { ...filter };
    delete novosFiltros[key];

    setFilter(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filter)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColors = () => {
            if (key === "nome") {
              return "cyan";
            } else if (key === "em_teste" && value) {
              return "green";
            }
            return "red";
          };

          return (
            <Tag
              closable
              onClose={() => onRemoveTags(key)}
              color={tagsColors(key)}
              key={key}
            >
              <text style={{ padding: 10 }}>
                {tag[key] +
                  ": " +
                  (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
              </text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{
          padding: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          Inserir
        </Button>
        <Button
          icon={<FilterOutlined />}
          onClick={() => setOpenFilter(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          Filtro
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={sequences}
        loading={loading}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => fetchRequest(page, pageSize, filter),
        }}
        title={() => getTags()}
        scroll={{ x: true }}
      />

      <ModalInserir
        onCancel={() => {
          setOpen(false);
          setId(null);
        }}
        visible={open}
        estadoSequence={setSequences}
        id={id}
      />

      <ModalDelete
        id={idDelete}
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        estadoSequences={setSequences}
      />

      <DrawerFilter
        onClose={() => setOpenFilter(false)}
        visible={openFilter}
        onPesquisarClick={onPesquisarClick}
        fetchRequest={() => setFilter({})}
      />
    </div>
  );
};
