import { Button, Pagination, Space, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { colors } from "../../Utils/temas";
import { requestsZAPI } from "./request";
import { ModalCreateInstance } from "./componentes/modals/createInstance";
import { StopOutlined, EditOutlined, FilterOutlined } from "@ant-design/icons";
import { ModalSignInstance } from "./componentes/modals/signInstance";
import { ModalCancelInstance } from "./componentes/modals/cancelInstance";
import { ModalViewInstance } from "./componentes/modals/viewInstance";
import { DrawerFilter } from "./componentes/drawer/filter";

export const ZAPI = () => {
  const [instancias, setInstancias] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSign, setOpenSign] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openViewInstance, setOpenViewInstance] = useState(false);

  const [instanceValues, setInstanceValue] = useState({ token: "", id: "" });

  const [viewInstance, setViewInstace] = useState({ id: "" });

  const [filtros, setFiltros] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalContent, setTotalContent] = useState(null);

  useEffect(() => {
    getInstances(currentPage, filtros);
  }, [filtros, currentPage]);

  const getInstances = async (page, params) => {
    setLoading(true);
    try {
      const response = await requestsZAPI.getInstances(page, params);
      if (response.status === 200) {
        const { content, total } = response.data;
        setInstancias(content);
        setTotalContent(total);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dataFormatada = (data) => {
    const date = new Date(data);
    const dateFormatted = date.toLocaleString().replace(",", "");
    return dateFormatted;
  };

  const formattStatus = (value) => {
    if (value === "CANCELLATION_PROCESS") {
      return "Em Cancelamento";
    }
    if (value === "PAID") {
      return "Pago";
    }
    if (value === "CANCELED") {
      return "Cancelado";
    }
    if (value === "TRIAL") {
      return "Trial";
    }
  };

  const colorsStatus = (value) => {
    if (value === "CANCELLATION_PROCESS") {
      return "orange";
    }
    if (value === "PAID") {
      return "green";
    }
    if (value === "CANCELED") {
      return "red";
    }
    if (value === "TRIAL") {
      return "blue";
    }
  };

  const columns = [
    { title: "Nome", dataIndex: "name" },
    { title: "ID", dataIndex: "id", width: 100 },
    { title: "Token", dataIndex: "token", width: 100 },
    {
      title: "Status",
      dataIndex: "whatsappConnected",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>
          {value ? "Conectada" : "Desconectada"}
        </Tag>
      ),
      width: 100,
    },
    {
      title: "Dt. Criação",
      dataIndex: "created",
      render: (item) => dataFormatada(item),
      width: 180,
    },
    {
      title: "Dt. Vencimento",
      dataIndex: "due",
      width: 180,
      render: (item) => {
        const timestamp = item;

        const date = new Date(timestamp);

        const formattedDate = date.toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        return <text>{formattedDate.replace(",", "")}</text>;
      },
    },
    {
      title: "Pagamento",
      dataIndex: "paymentStatus",
      render: (value) => (
        <Tag color={colorsStatus(value)}>{formattStatus(value)}</Tag>
      ),
      width: 100,
    },
    {
      title: "Ações",
      render: (_, render) => (
        <Space size="middle">
          <Tooltip title="Assinar">
            <EditOutlined onClick={() => handleSign(render)} />
          </Tooltip>

          <Tooltip title="Cancelar">
            <StopOutlined onClick={() => handleCancel(render)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSign = (value) => {
    setOpenSign(true);
    setInstanceValue({ id: value.id, token: value.token });
  };

  const handleCancel = (value) => {
    setOpenCancel(true);
    setInstanceValue({ id: value.id, token: value.token });
  };

  function onRemoverFiltro(tag) {
    const novosFiltros = { ...filtros };
    delete novosFiltros[tag];

    setFiltros(novosFiltros);
  }

  const tags = {
    query: "Nome/ID",
  };

  const getTagTitles = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          return (
            <Tag
              key={key}
              closable
              onClose={() => onRemoverFiltro(key)}
              color="orange"
            >
              <text
                style={{
                  padding: 10,
                }}
              >
                {tags[key] + `: ${value}`}
              </text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div style={{ marginBottom: 16 }}>
      <div
        style={{ padding: 8, justifyContent: "space-between", display: "flex" }}
      >
        <Button
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          onClick={() => setOpen(true)}
        >
          Criar instância
        </Button>

        <Button
          icon={<FilterOutlined />}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          onClick={() => setOpenDrawer(true)}
        >
          Filtros
        </Button>
      </div>
      <Table
        dataSource={instancias}
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
        loading={loading}
        title={getTagTitles}
      />
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "99%",
        }}
      >
        <Pagination
          total={totalContent}
          current={currentPage}
          onChange={(value) => setCurrentPage(value)}
          style={{ marginTop: 8 }}
          pageSize={15}
          showTotal={(total) =>
            `Total de instâncias: ${total} - exibindo ${
              instancias && instancias.length
            } por página`
          }
        />
      </div>

      <ModalCreateInstance
        onCancel={() => setOpen(false)}
        visible={open}
        onConfirm={(value) => {
          getInstances(currentPage, filtros);
          setOpenViewInstance(true);
          setViewInstace({ id: value.id });
        }}
      />
      <ModalSignInstance
        onCancel={() => {
          setOpenSign(false);
          setInstanceValue({ id: "", token: "" });
        }}
        visible={openSign}
        onConfirm={() => getInstances(currentPage, filtros)}
        id={instanceValues.id}
        token={instanceValues.token}
      />
      <ModalCancelInstance
        id={instanceValues.id}
        onCancel={() => {
          setOpenCancel(false);
          setInstanceValue({ id: "", token: "" });
        }}
        token={instanceValues.token}
        visible={openCancel}
        onConfirm={() => getInstances(currentPage, filtros)}
      />
      <ModalViewInstance
        onCancel={() => setOpenViewInstance(false)}
        visible={openViewInstance}
        onOk={() => {
          setFiltros({ query: viewInstance.id });
          setOpenViewInstance(false);
        }}
      />
      <DrawerFilter
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}
        onFinish={(value) => {
          setFiltros(value);
          setCurrentPage(1);
        }}
        onClear={() => setFiltros({})}
        filtros={filtros}
      />
    </div>
  );
};
