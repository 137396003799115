import { Button, Drawer, Form, Select } from "antd";
import { colors } from "../../../../Utils/temas";
import { useForm } from "antd/lib/form/Form";
import { FilterOutlined } from "@ant-design/icons";

export const DrawerFilter = ({
  limpar,
  visible,
  onClose,
  options,
  onFinish,
  optionsSistema,
}) => {
  const [form] = useForm();

  return (
    <Drawer
      visible={visible}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      title="Filtros"
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "20%" }}>
            <Form.Item name="uf" label="UF:">
              <Select options={options} />
            </Form.Item>
          </div>

          <div style={{ width: "78%" }}>
            <Form.Item name="sistema_id" label="Sistema:">
              <Select options={optionsSistema} />
            </Form.Item>
          </div>
        </div>

        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <Button
            style={{ background: colors.secondary, color: "white" }}
            htmlType="submit"
            icon={<FilterOutlined />}
          >
            Filtrar
          </Button>
          <Button
            onClick={() => {
              limpar();
              form.resetFields();
            }}
          >
            Limpar
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
